import { FC } from "react";
import styles from "./Input.module.css";
interface PropTypes {
  value: string;
  onChange: (newValue: string) => void;
  label: string;
  placeholder: string;
  isMultiline?: boolean;
  rows?: number;
  error?: string;
}
const InputField: FC<PropTypes> = ({
  value,
  onChange,
  label,
  placeholder,
  isMultiline = false,
  rows = 4,
  error,
}) => {
  const style = error ? { borderColor: "red" } : {};
  return (
    <div className={styles.input_field}>
      <label>{label}</label>
      {isMultiline ? (
        <textarea
          placeholder={placeholder}
          rows={rows}
          value={value}
          style={style}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      ) : (
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          style={style}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      )}
      {error ? <div className={styles.error}>{error}</div> : null}
    </div>
  );
};

export default InputField;
