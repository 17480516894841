import { FC } from 'react';
import styles from './Header.module.css';
interface PropTypes {
  picURL: string;
  logoUrl: string;
}

const Header: FC<PropTypes> = ({ picURL, logoUrl }) => {
  return (
    <div className={styles.header}>
      <div className={styles.bgr}>
        <img
          className={styles.bgr_img}
          src={picURL}
          width={392}
          height={292}
          alt="bgr"
        />
        <img
          className={styles.logo}
          src={logoUrl}
          width={102}
          height={31}
          alt="logo"
        />
        <div className={styles.slogan}>
          Completely personal <br /> and{' '}
          <span className={styles.never}> never </span> destined for landfill
        </div>
      </div>
    </div>
  );
};
export default Header;
