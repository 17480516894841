import React, { FC, useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import ButtonFooter from "./components/Footer/Footer";
import InputField from "./components/Input/Input";

export interface GiftPaymentRequest {
  firstName: string;
  lastName: string;
  email: string;
  giftAmount: number;
  recFirstName: string;
  recLastName: string;
  recEmail: string;
  giftMessage: string;
}

export interface GiftPaymentErrors {
  firstName: string;
  lastName: string;
  email: string;
  giftAmount: string;
  recFirstName: string;
  recLastName: string;
  recEmail: string;
  giftMessage: string;
}

const serverUrl = "https://indyx-server-3tg67jmjeq-uc.a.run.app";

const getNonEmptyError = (str: string) => {
  if (str === "") {
    return "Field can't be empty";
  }
  return "";
};

const getEmailError = (str: string) => {
  if (str === "") {
    return "Field can't be empty";
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(str)) {
    return "Please double check this email address";
  }
  return "";
};

const getAmountError = (num: number) => {
  if (!num || num < 0) {
    return "Please enter an amount greater than 0";
  }
  return "";
};

function App() {
  const path = window.location.pathname;
  const pathArray = path.toLowerCase().split("/");
  const isSuccess = pathArray[1] === "success";
  const isCancel = pathArray[1] === "cancel";

  const [paymentRequest, setPaymentRequest] = useState<GiftPaymentRequest>({
    firstName: "",
    lastName: "",
    email: "",
    giftAmount: 0,
    recFirstName: "",
    recLastName: "",
    recEmail: "",
    giftMessage: "",
  });

  const [paymentErrors, setPaymentErrors] = useState<GiftPaymentErrors>({
    firstName: "",
    lastName: "",
    email: "",
    giftAmount: "",
    recFirstName: "",
    recLastName: "",
    recEmail: "",
    giftMessage: "",
  });

  const [loading, setLoading] = useState<boolean>(false);

  const amountStr =
    paymentRequest.giftAmount <= 0 ? "" : paymentRequest.giftAmount.toString();

  if (isSuccess) {
    return (
      <div className="App">
        <Header
          logoUrl={`/images/INDYX_Primary_White.png`}
          picURL={`/images/pexels-anna-malysheva-12186615-2_d5wcyp.png`}
        />
        <div className="main">
          <div className="main-title">Thank you for gifting Indyx!</div>
          <br />
          <br />
          We have successfully processed your purchase! You and your recipient
          will have an email in your inbox shortly with the gifting code and
          instructions on how to redeem this credit in the Indyx app.
          <br />
          <br />
          If you have any questions, please reach out to help@myindyx.com for
          further assistance.
          <br />
        </div>

        <ButtonFooter
          text="BUY AGAIN"
          onClick={() => {
            window.location.pathname = "/";
          }}
        />
      </div>
    );
  }

  if (isCancel) {
    return (
      <div className="App">
        <Header
          logoUrl={`/images/INDYX_Primary_White.png`}
          picURL={`/images/pexels-anna-malysheva-12186615-2_d5wcyp.png`}
        />
        <div className="main">
          <div className="main-title">Oops, something went wrong.</div>
          <br />
          <br />
          You either cancelled the transaction or we weren’t able to process
          this transaction based on the information you provided. Please try
          again.
          <br />
          <br />
          If you have any questions, please reach out to help@myindyx.com for
          further assistance.
          <br />
        </div>

        <ButtonFooter
          text="TRY AGAIN"
          onClick={() => {
            window.location.pathname = "/";
          }}
        />
      </div>
    );
  }

  const active = paymentRequest.firstName && paymentRequest.lastName;

  return (
    <div className="App">
      <Header
        logoUrl={`/images/INDYX_Primary_White.png`}
        picURL={`/images/pexels-anna-malysheva-12186615-2_d5wcyp.png`}
      />
      <div className="main">
        We’ve all given and received gifts that, frankly, we could have done
        without.
        <br />
        <br />
        Rather than gifting yet another tired sweater, choose instead to give a
        super-personalized Indyx digital wardrobe experience that’s guaranteed
        to never end up in the trash.
        <br />
        <br /> How it works: <br />
        <ol className="how_works">
          <li>Purchase a gift credit in any U.S. dollar amount</li>
          <li>
            Both you and the recipient will receive an email with an unique
            redemption code
          </li>
          <li>
            Enter the code in the Indyx app to redeem the gift credit amount
          </li>
        </ol>
        Indyx gift credits never expire and are redeemable for non-subscription
        services.
        <br />
        <form className="form">
          <InputField
            value={paymentRequest.firstName}
            onChange={(val) => {
              setPaymentRequest({ ...paymentRequest, firstName: val });
              setPaymentErrors({ ...paymentErrors, firstName: "" });
            }}
            label="Your First Name *"
            placeholder="Enter your first name"
            error={paymentErrors.firstName}
          />
          <InputField
            value={paymentRequest.lastName}
            onChange={(val) => {
              setPaymentRequest({ ...paymentRequest, lastName: val });
              setPaymentErrors({ ...paymentErrors, lastName: "" });
            }}
            label="Your Last Name *"
            placeholder="Enter your last name"
            error={paymentErrors.lastName}
          />
          <InputField
            value={paymentRequest.email}
            onChange={(val) => {
              setPaymentRequest({ ...paymentRequest, email: val });
              setPaymentErrors({ ...paymentErrors, email: "" });
            }}
            label="Your Email *"
            placeholder="Enter your email"
            error={paymentErrors.email}
          />
          <InputField
            value={amountStr}
            onChange={(val) => {
              try {
                if (val === "") {
                  setPaymentRequest({ ...paymentRequest, giftAmount: 0 });
                  setPaymentErrors({ ...paymentErrors, giftAmount: "" });
                } else {
                  const num = parseInt(val);
                  setPaymentRequest({ ...paymentRequest, giftAmount: num });
                  setPaymentErrors({ ...paymentErrors, giftAmount: "" });
                }
              } catch (e) {}
            }}
            label="Gift Card Amount *"
            placeholder="Enter amount in U.S. Dollars"
            error={paymentErrors.giftAmount}
          />
          <InputField
            value={paymentRequest.recFirstName}
            onChange={(val) => {
              setPaymentRequest({ ...paymentRequest, recFirstName: val });
              setPaymentErrors({ ...paymentErrors, recFirstName: "" });
            }}
            label="Recipient First Name *"
            placeholder="Enter recipient's first name"
            error={paymentErrors.recFirstName}
          />
          <InputField
            value={paymentRequest.recLastName}
            onChange={(val) => {
              setPaymentRequest({ ...paymentRequest, recLastName: val });
              setPaymentErrors({ ...paymentErrors, recLastName: "" });
            }}
            label="Recipient Last Name *"
            placeholder="Enter recipient's last name"
            error={paymentErrors.recLastName}
          />
          <InputField
            value={paymentRequest.recEmail}
            onChange={(val) => {
              setPaymentRequest({ ...paymentRequest, recEmail: val });
              setPaymentErrors({ ...paymentErrors, recEmail: "" });
            }}
            label="Recipient Email *"
            placeholder="Enter recipient's email"
            error={paymentErrors.recEmail}
          />
          <InputField
            value={paymentRequest.giftMessage}
            onChange={(val) => {
              setPaymentRequest({ ...paymentRequest, giftMessage: val });
              setPaymentErrors({ ...paymentErrors, giftMessage: "" });
            }}
            isMultiline={true}
            label="Gift Message (optional)"
            placeholder="Enter a message for your recipient"
            rows={5}
          />
          <br />
        </form>
      </div>

      <ButtonFooter
        text="PAY"
        onClick={async () => {
          if (loading) {
            return;
          }
          const errors: GiftPaymentErrors = {
            firstName: getNonEmptyError(paymentRequest.firstName),
            lastName: getNonEmptyError(paymentRequest.firstName),
            email: getEmailError(paymentRequest.email),
            giftAmount: getAmountError(paymentRequest.giftAmount),
            recFirstName: getNonEmptyError(paymentRequest.recFirstName),
            recLastName: getNonEmptyError(paymentRequest.recLastName),
            recEmail: getEmailError(paymentRequest.recEmail),
            giftMessage: "",
          };
          setPaymentErrors(errors);
          const hasErrors =
            errors.firstName ||
            errors.lastName ||
            errors.email ||
            errors.giftAmount ||
            errors.recFirstName ||
            errors.recLastName ||
            errors.recEmail ||
            errors.giftMessage;
          if (!hasErrors) {
            try {
              setLoading(true);
              const res = await fetch(`${serverUrl}/payments/giftPayment`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(paymentRequest),
              });
              const json = await res.json();
              const url = json.url;
              if (url) {
                window.location.href = url;
              } else {
                window.location.pathname = "/cancel";
              }
            } finally {
              setLoading(false);
            }
          }
        }}
      />
    </div>
  );
}

export default App;
